import React, { Suspense, lazy } from 'react';

import './css/App.css';


//import MenuScreen from './screens/MenuScreen';
//import TermScreen from './screens/TermScreen';
//import HomeScreen from './screens/HomeScreen';
//import FirstPerson from './screens/FirstPerson';
//import SecondPerson from './screens/SecondPerson';
//import AskForGuestsScreen from './screens/AskForGuestsScreen';
//import CartScreen from './screens/CartScreen';
//import OrderPlacedScreen from './screens/OrderPlacedScreen';
//import ScanMenuScreen from './screens/ScanMenuScreen';
//import InsertCodeScreen from './screens/InsertCodeScreen';
//import MenuNoShowScreen from './screens/MenuNoShowScreen';
//import ShowHostCodeScreen from './screens/ShowHostCodeScreen';
//import AskForSplitTheBillScreen from './screens/AskForSplitTheBillScreen';
//import HowManyWillContributeScreen from './screens/HowManyWillContributeScreen';
//import ShowCodesScreen from './screens/ShowCodesScreen';
//import ItemScreen from './screens/ItemScreen';
//import TransferCoordinate from './screens/TransferCoordinate';
//import AllowOrdersScreen from './screens/AllowOrdersScreen';
//import PolicyScreen from './screens/PolicyScreen';
//import AskContactScreen from './screens/AskContactScreen';
//import TermScreen from './screens/TermScreen';
//import PaymentScreen from './screens/Payment';
import BeatLoader from "react-spinners/BeatLoader";
import config from "./config";
import Pusher from 'pusher-js';
import AsyncStorage from '@react-native-community/async-storage';
import axios from 'axios';
//import OrderSubmittedScreen from './screens/OrderSubmittedScreen';
//import OrderHistoryScreen from './screens/OrderHistoryScreen';
//import ChangeLanguageScreen from './screens/ChangeLanguageScreen';
//import ModalAddToHomeScreenIOS from './components/ModalAddToHomeScreenIOS';
//import ModalAddToHomeScreenAndroid from './components/ModalAddToHomeScreenAndroid';
//import AskDeliveryOrTakeAwayScreen from './screens/AskContactScreen';


import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

const MenuScreen = lazy(() => import('./screens/MenuScreen'));
const TermScreen = lazy(() => import('./screens/TermScreen'));
const HomeScreen = lazy(() => import('./screens/HomeScreen'));
const FirstPerson = lazy(() => import('./screens/FirstPerson'));
const SecondPerson = lazy(() => import('./screens/SecondPerson'));
const AskForGuestsScreen = lazy(() => import('./screens/AskForGuestsScreen'));
const CartScreen = lazy(() => import('./screens/CartScreen'));
const OrderPlacedScreen = lazy(() => import('./screens/OrderPlacedScreen'));
const ScanMenuScreen = lazy(() => import('./screens/ScanMenuScreen'));
const InsertCodeScreen = lazy(() => import('./screens/InsertCodeScreen'));
const MenuNoShowScreen = lazy(() => import('./screens/MenuNoShowScreen'));
const ShowHostCodeScreen = lazy(() => import('./screens/ShowHostCodeScreen'));
const AskForSplitTheBillScreen = lazy(() => import('./screens/AskForSplitTheBillScreen'));
const HowManyWillContributeScreen = lazy(() => import('./screens/HowManyWillContributeScreen'));
const ShowCodesScreen = lazy(() => import('./screens/ShowCodesScreen'));
const ItemScreen = lazy(() => import('./screens/ItemScreen'));
const TransferCoordinate = lazy(() => import('./screens/TransferCoordinate'));
const AllowOrdersScreen = lazy(() => import('./screens/AllowOrdersScreen'));
const PolicyScreen = lazy(() => import('./screens/PolicyScreen'));
const AskContactScreen = lazy(() => import('./screens/AskContactScreen'));
const PaymentScreen = lazy(() => import('./screens/Payment'));
//const BeatLoader = lazy(() => import('react-spinners/BeatLoader'));
//const config = lazy(() => import('./config'));
//const Pusher = lazy(() => import('pusher-js'));
//const AsyncStorage = lazy(() => import('@react-native-community/async-storage'));
//const axios = lazy(() => import('axios'));
const OrderSubmittedScreen = lazy(() => import('./screens/OrderSubmittedScreen'));
const OrderHistoryScreen = lazy(() => import('./screens/OrderHistoryScreen'));
const ChangeLanguageScreen = lazy(() => import('./screens/ChangeLanguageScreen'));
const ModalAddToHomeScreenIOS = lazy(() => import('./components/ModalAddToHomeScreenIOS'));
const ModalAddToHomeScreenAndroid = lazy(() => import('./components/ModalAddToHomeScreenAndroid'));


//import axios from 'axios';

if (!String.prototype.trim) {
  String.prototype.trim = function () {
    return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
  };
}

function clearCart(props) {
  AsyncStorage.setItem('poolside' + props.match.params.restaurantId);
 
  return(
    <div>Cache clear successfully</div>
  )
}

class App extends React.Component{
  state = { isReady: false };
  channel = '';
  pusher = '';
  loadedPusher = false;
  isStandalone = false;
  whereIsShare = 'bottom';
  promptIntercepted = false;
  deferredPrompt = false;
  isAddToHome = false;
  apiURL = config.apiURL;
  fetchData = async () => {
   
    var postData = {
      tableId: this.tableId64,
      userId: this.userId,
      changeHost: false
    };

    var timestamp = new Date().getTime();
    var requestURL = this.apiURL + 'scan-table' + "?timestamp=" + timestamp;
    axios({
      method: 'post',
      url: requestURL,
      data: postData
    })
    .then(async (response) => {
      this.restaurantId = response.data.restaurant.RestaurantID;
      this.restaurantData = response.data.restaurant;
      this.menuItems = response.data.menuItems;
      this.menuCategories = response.data.menuCategories;

      AsyncStorage.setItem('tableId64', this.tableId64);
      AsyncStorage.setItem('tempuserid', response.data.restaurant.userId);
      AsyncStorage.setItem("menuItems", JSON.stringify(this.menuItems));
      AsyncStorage.setItem("itemBadges", JSON.stringify(response.data.itemBadges));
      AsyncStorage.setItem("menuCategories", JSON.stringify(this.menuCategories));
      AsyncStorage.setItem("restaurant", JSON.stringify(this.restaurantData));
      AsyncStorage.setItem('poolside-restaurantid', this.restaurantId);
      AsyncStorage.setItem('poolside-table', response.data.tableId);
      AsyncStorage.setItem('combo-category', JSON.stringify(response.data.comboCategory));
      AsyncStorage.setItem('combo-builder', JSON.stringify(response.data.comboBuilder));
      AsyncStorage.setItem('guests', response.data.restaurant.guests);
      AsyncStorage.setItem('isHost', response.data.restaurant.isHost);
      AsyncStorage.setItem('askForCourse', response.data.restaurant.askForCourse);
      AsyncStorage.setItem('askForGuests', response.data.restaurant.askForGuests);
      AsyncStorage.setItem('isAllGuestsCanOrder', response.data.restaurant.isAllGuestsCanOrder);
      AsyncStorage.setItem('isBillCoordinator', response.data.restaurant.isBillCoordinator);
      AsyncStorage.setItem('isNoCoordinator', response.data.restaurant.isNoCoordinator);
      AsyncStorage.setItem('isFullTableCoordinator', response.data.restaurant.isFullTableCoordinator);

      AsyncStorage.setItem('eOrdering', response.data.restaurant.eOrdering);
      AsyncStorage.setItem('ePayment', response.data.restaurant.ePayment);
      AsyncStorage.setItem('isContactDetails', response.data.restaurant.isContactDetails);
      AsyncStorage.setItem('isVideoPerItem', response.data.restaurant.isVideoPerItem);

      AsyncStorage.setItem('isVideoFeature', response.data.restaurant.isVideoFeature);
      AsyncStorage.setItem('VideoLink', response.data.restaurant.VideoLink);

      AsyncStorage.setItem('autoPlayVideo', response.data.restaurant.autoPlayVideo);
      AsyncStorage.setItem('autoLoopVideo', response.data.restaurant.autoLoopVideo);

      AsyncStorage.setItem('isPaymentUpFront', response.data.restaurant.isPaymentUpFront);
      AsyncStorage.setItem('isPaymentAtEndOfMeal', response.data.restaurant.isPaymentAtEndOfMeal);
      AsyncStorage.setItem('showComboOptionsImmediately', response.data.restaurant.showComboOptionsImmediately);
      AsyncStorage.setItem('isCallForService', response.data.restaurant.isCallForService);
      AsyncStorage.setItem('NumberOfDecimal', response.data.restaurant.NumberOfDecimal);
      AsyncStorage.setItem('isCountAddOnQuantity', response.data.restaurant.isCountAddOnQuantity);
      AsyncStorage.setItem('isCollection', response.data.restaurant.isCollection);
      AsyncStorage.setItem('isShowDescriptionInList', response.data.restaurant.isShowDescriptionInList);
      AsyncStorage.setItem('languages', JSON.stringify(response.data.Languages));
      AsyncStorage.setItem('isShowPowered', response.data.restaurant.isShowPowered);
      
      AsyncStorage.setItem('isDelivery', response.data.restaurant.isDelivery);
      AsyncStorage.setItem('requireDeliveryAndTakeAwayDetail', response.data.restaurant.requireDeliveryAndTakeAwayDetail);
      AsyncStorage.setItem('isEnableDelivery', response.data.isEnableDelivery);

      AsyncStorage.setItem('Ingredents', response.data.Ingredents);
      AsyncStorage.setItem('IngredentItems', response.data.IngredentItems);
      AsyncStorage.setItem('isUseCustomIngredent', response.data.restaurant.isUseCustomIngredent);

      AsyncStorage.setItem('CustomerRequests', JSON.stringify(response.data.CustomerRequests));
      AsyncStorage.setItem('WelcomeMessage', response.data.restaurant.WelcomeMessage);
      AsyncStorage.setItem('HotLine', response.data.restaurant.CallToAction);
      AsyncStorage.setItem('Manifest', response.data.restaurant.manifestfile);
      AsyncStorage.setItem('RestaurantName', response.data.restaurant.RestaurantName);
      AsyncStorage.setItem('countryCode', response.data.restaurant.countryCode);
      AsyncStorage.setItem('webappHeaderBackground', response.data.restaurant.webappHeaderBackground);
      AsyncStorage.setItem('lat', response.data.restaurant.lat);
      AsyncStorage.setItem('lng', response.data.restaurant.lng);
      AsyncStorage.setItem('restaurantAddress', response.data.restaurant.restaurantAddress);
      AsyncStorage.setItem('maximumDistanceDelivery', response.data.restaurant.maximumDistanceDelivery);
      AsyncStorage.setItem('minOrder', response.data.restaurant.minOrder);
      AsyncStorage.setItem('deliveryCompany', response.data.restaurant.deliveryCompany);

      //console.log(response.data.Stores);
      AsyncStorage.setItem('stores-' + response.data.restaurant.RestaurantID, JSON.stringify(response.data.Stores));
      AsyncStorage.setItem('timeZoneId-' + response.data.restaurant.RestaurantID, response.data.restaurant.timeZoneId);

      this.restaurantName = response.data.restaurant.RestaurantName;
      document.title = response.data.restaurant.RestaurantName;

      //console.log(response.data.Languages);
      if(typeof response.data.Languages !== "undefined" && response.data.Languages !== '' && response.data.Languages !== null && response.data.Languages !== 'null'){
        var defaultLanguage = await AsyncStorage.getItem('defaultLanguage');
        if(typeof defaultLanguage === "undefined" || defaultLanguage === '' || defaultLanguage === null || defaultLanguage === 'null'){
          response.data.Languages.forEach(language => {
            if(language.IsDefault === true){
              AsyncStorage.setItem('defaultLanguage', JSON.stringify(language));
              AsyncStorage.setItem('defaultLanguageByRestaurant', response.data.restaurant.RestaurantID);
            }
          })
        }else{
          var langData = JSON.parse(defaultLanguage);
          var isFound = false;
          response.data.Languages.forEach(language => {
            if(language.AppLangId === langData.AppLangId){
              isFound = true;
            }
          });
          if(isFound === false){
            response.data.Languages.forEach(language => {
              if(language.IsDefault === true){
                console.log('set default again');
                AsyncStorage.setItem('defaultLanguage', JSON.stringify(language));
                AsyncStorage.setItem('defaultLanguageByRestaurant', response.data.restaurant.RestaurantID);
              }
            })
          }else{
            var defaultLanguageByRestaurant = await AsyncStorage.getItem('defaultLanguageByRestaurant');
            if(typeof defaultLanguageByRestaurant === "undefined" || defaultLanguageByRestaurant === '' 
              || defaultLanguageByRestaurant === null || defaultLanguageByRestaurant === 'null'){
                response.data.Languages.forEach(language => {
                  if(language.IsDefault === true){
                    console.log('set default again');
                    AsyncStorage.setItem('defaultLanguage', JSON.stringify(language));
                    AsyncStorage.setItem('defaultLanguageByRestaurant', response.data.restaurant.RestaurantID);
                  }
                })
            }else{
              if(parseInt(defaultLanguageByRestaurant) !== response.data.restaurant.RestaurantID){
                response.data.Languages.forEach(language => {
                  if(language.IsDefault === true){
                    console.log('set default again');
                    AsyncStorage.setItem('defaultLanguage', JSON.stringify(language));
                    AsyncStorage.setItem('defaultLanguageByRestaurant', response.data.restaurant.RestaurantID);
                  }
                })
              }
            }
          }
        }


        this.checkManifest();
      }else{
        AsyncStorage.setItem('defaultLanguage', '');
      }

      AsyncStorage.setItem('scan4menu-version', this.version);

      this.setState({isReady: true});
    })
    .catch(error => console.error(error))
  }

  checkStandalone = () => {
    return 'standalone' in window.navigator && window.navigator.standalone;
    //return (window.matchMedia('(display-mode: standalone)').matches);
  }

  checkManifest = async () => {
    //manifest
    var manifestData = await AsyncStorage.getItem('Manifest');
    var isProcess = false;
    if(window.location.pathname.indexOf('scanmenu/table') < 0 &&
      typeof this.tableId64 !== "undefined" && this.tableId64 !== null && this.tableId64 !== 'null' && this.tableId64 !== ''){
        isProcess = true;
    }

    if(isProcess === true){
      this.isAddToHome = false;
      if(typeof manifestData !== 'undefined' && manifestData !== null
        && manifestData !== 'null' && manifestData !== ''){

          var manifestJson = {
            "short_name": this.restaurantName,
            "name": this.restaurantName,
            "icons": [
              {
                "src": config.rootURL + "manifest/logo/favicon-"+manifestData+".ico",
                "sizes": "64x64",
                "type": "image/x-icon"
              },
              {
                "src": config.rootURL + "manifest/logo/logo192-"+manifestData+".png",
                "type": "image/png",
                "sizes": "192x192",
                "purpose": "maskable"
              },
              {
                "src": config.rootURL + "manifest/logo/logo512-"+manifestData+".png",
                "type": "image/png",
                "sizes": "512x512"
              }
            ],
            "start_url":  config.rootURL + "scanmenu/table/" + this.tableId64,
            "display": "standalone",
            "theme_color": "#000000",
            "background_color": "#ffffff"
          };
          //console.log(manifestJson);
          var stringManifest = JSON.stringify(manifestJson);
          var BOM = new Uint8Array([0xEF,0xBB,0xBF]);
          var blob = new Blob([BOM, stringManifest], {type: 'application/json',encoding:"UTF-8",});
          var manifestURL = URL.createObjectURL(blob);


          //var filename = config.rootURL + "manifest/manifest-"+manifestData+".json";
          var map = document.getElementById("manifestfile");

          if(typeof map !== "undefined" && map !== null && map !== 'null'){
            map.parentNode.removeChild(map);
          }

          var styleEl = document.createElement('link');
          styleEl.rel = "manifest";
          styleEl.id = "manifestfile";
          styleEl.href = manifestURL;
          document.head.appendChild(styleEl);

          this.isAddToHome = true;


          var iconfilename = config.rootURL + "manifest/logo/favicon-"+manifestData+".ico";
          var iconId = document.getElementById("iconId");

          if(typeof iconId !== "undefined" && iconId !== null && iconId !== 'null'){
            iconId.parentNode.removeChild(iconId);
          }

          var styleEl = document.createElement('link');
          styleEl.rel = "icon";
          styleEl.id = "iconId";
          styleEl.href = iconfilename;
          styleEl.sizes = "64x64";
          document.head.appendChild(styleEl);

          var icontouchname = config.rootURL + "manifest/logo/logo192-"+manifestData+".png";
          var iconTouchId = document.getElementById("iconTouchId");

          if(typeof iconTouchId !== "undefined" && iconTouchId !== null && iconTouchId !== 'null'){
            iconTouchId.parentNode.removeChild(iconTouchId);
          }

          var styleEl = document.createElement('link');
          styleEl.rel = "apple-touch-icon";
          styleEl.id = "iconTouchId";
          styleEl.href = icontouchname;
          styleEl.sizes = "192x192";
          document.head.appendChild(styleEl);
      }else{
        var map = document.getElementById("manifestfile");
        if(typeof map === "undefined" || map === null || map === 'null'){
          var styleEl = document.createElement('link');
          styleEl.rel = "manifest";
          styleEl.id = "manifestfile";
          styleEl.href = config.rootURL + "manifest/manifest.json";
          document.head.appendChild(styleEl);
        }

        var iconfilename = config.rootURL + "manifest/favicon.ico";
        var iconId = document.getElementById("iconId");
        if(typeof iconId === "undefined" || iconId === null || iconId === 'null'){
          var styleEl = document.createElement('link');
          styleEl.rel = "icon";
          styleEl.id = "iconId";
          styleEl.sizes = "64x64";
          styleEl.href = iconfilename;
          document.head.appendChild(styleEl);
        }

        var icontouchname = config.rootURL + "manifest/logo192.png";
        var iconTouchId = document.getElementById("iconTouchId");
        if(typeof iconTouchId === "undefined" || iconTouchId === null || iconTouchId === 'null'){
          var styleEl = document.createElement('link');
          styleEl.rel = "apple-touch-icon";
          styleEl.id = "iconTouchId";
          styleEl.href = icontouchname;
          styleEl.sizes = "192x192";
          document.head.appendChild(styleEl);
        }
      }

      //this.setDoneLoad(isAddToHome);
    }
    
  }

  trackStandalone = () => {
    if (this.checkStandalone()) {
      this.isStandalone = true;
    }
  }

  checkUserAgent = () => {
    this.userAgent = navigator.userAgent.toLowerCase();
    const uaString = this.userAgent;

    this.isChrome = /chrome/.test(uaString);
    this.isExplorer = /msie/.test(uaString);
    this.isExplorer_11 = /rv:11/.test(uaString);
    this.isFirefox  = /firefox/.test(uaString);
    this.isSafari = /safari/.test(uaString);
    this.isOpera = /opr/.test(uaString);
    this.isEdgeDesktop = /edge/.test(uaString);
    this.isEdgeiOS = /edgios/.test(uaString);
    this.isEdgeAndroid = /edga/.test(uaString);

    this.isIOS = /ipad|iphone|ipod/.test(uaString);
    this.isMobile = /mobile/.test(uaString);
    if ((this.isChrome) && (this.isSafari)) { this.isSafari = false; }
    if ((this.isChrome) && (  (this.isEdgeDesktop) ||
                              (this.isEdgeiOS) ||
                              (this.isEdgeAndroid) )  ) { this.isChrome = false; }
    if ((this.isSafari) && (  (this.isEdgeDesktop) ||
                              (this.isEdgeiOS) ||
                              (this.isEdgeAndroid) )  ) { this.isSafari = false; }
    if ((this.isChrome) && (this.isOpera)) { this.isChrome = false; }

    if(this.isSafari && this.isIOS){
      var isChrome = /crios/.test(uaString);
      if(isChrome){
        this.isSafari = false;
      }
    }

  }

  iOSSafariShow() {
    if (this.isSafari && this.isIOS && !this.isStandalone ) {
      return true;
    } else {
      return false;
    }
  }

  setModalIOSHomeScreen = element => {
    this.ModalIOSHomeScreen = element;
  }

  setModalAndroidHomeScreen = element => {
    this.ModalAndroidHomeScreen = element;
  }

  addHomeScreen = () => {
    // call on custom button click
    //this.customButtonClicked = true;

    if (!this.deferredPrompt) {
      console.log('deferredPrompt null');
      return;
    }

    // Show the prompt
    this.deferredPrompt.prompt();
    this.deferredPromptShown = true;

    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {

        if (choiceResult.outcome === 'accepted') {
            // no matter the outcome, the prompt cannot be reused ON MOBILE
            // for 3 months or until browser cache is cleared?
        } else {
            this.deferredPromptRejected = true;
        }

      });
  }

  getLanguage = () => {
    return navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en';
  }

  componentDidMount = async () => {
    
    //this.setState({isReady: true});

    if(this.apiURL !== "http://localhost:52436/"){
      var countryCode = await AsyncStorage.getItem('countryCode');
      if(typeof countryCode !== "undefined" && countryCode !== "" && countryCode !== null && countryCode !== 'null'){
        if(countryCode === "VN"){
          this.apiURL = "https://vn.scan4menu.net/";
        }
      }else{
        var browserLang = this.getLanguage();
        if(typeof browserLang !== "undefined" && (browserLang === "vi" || browserLang === "vi-VN")){
          this.apiURL = "https://vn.scan4menu.net/";
        }
      }
    }

    //check if new version available
    var restaurantName = await AsyncStorage.getItem('RestaurantName');
    this.restaurantName = restaurantName;
    if(typeof restaurantName !== 'undefined' && restaurantName !== null && restaurantName !== 'null' && restaurantName !== ''){
      document.title = restaurantName;
    }

    var _this = this;
    
    
    
    this.tableId64 = await AsyncStorage.getItem('tableId64');
    this.userId = await AsyncStorage.getItem('tempuserid');
    if(this.userId === null || this.userId === 'null'){
      this.userId = '';
    }
    //console.log(this.userId);
    if(window.location.pathname.indexOf('scanmenu/table') < 0){
      this.checkManifest();

      if(this.tableId64 !== null && this.tableId64 !== 'null' && this.tableId64 !== ''){
        var version = await AsyncStorage.getItem('scan4menu-version');
        //console.log(this.tableId64);
        var timestamp = new Date().getTime();
        
        axios({
          method: 'get',
          url: config.apiURL + 'web-version.json' + "?timestamp=" + timestamp
        })
        .then((response) => {
          
          if(version === null || version === ''){
            this.fetchData();
            
          }else{
            if(parseInt(version) !== response.data.version){
              //console.log(window.location.pathname);
              this.version = response.data.version;
              this.fetchData();
             
            }else{
              //this.setState({isReady: true});
              //this.checkManifest();
            }
          }
       
        })
        .catch(error => console.error(error));
       
        this.checkRevision();
      }else{
        //this.setState({isReady: true});

        //this.checkManifest();
      }
    }else{
      //this.setState({isReady: true});
      //this.checkManifest();
      //this.checkRevision();
    }

    

    if(this.loadedPusher === false){
      this.initPusher();
    }

    
    /*
    var _this = this;
    setTimeout(function(){
      if(typeof _this.MenuScreen != 'undefined' && _this.MenuScreen != null){
        _this.MenuScreen.test();
      }
      
    }, 10000)*/
  
    
    /*
    const pusher = new Pusher(config.pusherAppKey, {
      cluster: config.pusherCluster,
      encrypted: true,
    });
    const channel = pusher.subscribe('host-channel');
    channel.bind('sub', async (data) => {
      var tableId = await AsyncStorage.getItem('poolside-table');
      var userId = await AsyncStorage.getItem('tempuserid');
      if(parseInt(tableId) === parseInt(data.tableId)){
        //console.log(userId);
        //console.log(data.userId);
        if(userId === data.userId){
          AsyncStorage.setItem('isHost', 'true');
          AsyncStorage.setItem('e-ordering', 'true');
          console.log('isHost');
        }else{
          AsyncStorage.setItem('isHost', 'false');
          AsyncStorage.setItem('e-ordering', 'false');
          console.log('not isHost');
        }
      }
      //console.log(data);
      //console.log(tableId);
    });

    channel.bind('countGuests', async (data) => {
      var tableId = await AsyncStorage.getItem('poolside-table');
      if(parseInt(tableId) === parseInt(data.tableId)){
        AsyncStorage.setItem('guests', data.guests);
      }
    });*/

    console.log("Add to home android before call beforeinstallprompt");
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log("Add to home android detected");
      // show the add button
      _this.promptIntercepted = true;
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // no matter what, the snack-bar shows in 68 (06/16/2018 11:05 AM)
      e.preventDefault();
      // Stash the event so it can be displayed when the user wants.
      _this.deferredPrompt = e;

      
      
    });
  }

  checkRevision = async () => {
    //var restaurantId = await AsyncStorage.getItem('poolside-restaurantid');
    var tableId64Cached = await AsyncStorage.getItem('tableId64');

    if(typeof tableId64Cached !== 'undefined' && tableId64Cached !== null && tableId64Cached !== "" && tableId64Cached !== "null"){
      var revisionNumber = await AsyncStorage.getItem('revisionNumber-' + tableId64Cached);
      var postData = {
        tableIdCached: tableId64Cached
      };
  
      var timestamp = new Date().getTime();
      var requestURL = this.apiURL + 'table-revision' + "?timestamp=" + timestamp;
      axios({
        method: 'post',
        url: requestURL,
        data: postData
      })
      .then(async (response) => {
        if(response.data.isSuccess === true){
          if(typeof revisionNumber === 'undefined' || revisionNumber === null || revisionNumber === '' || revisionNumber === 'null'){
            AsyncStorage.setItem('revisionNumber-' + tableId64Cached, response.data.RevisionNumber);
          }else if(parseInt(revisionNumber) < response.data.RevisionNumber){
            this.fetchData();
            AsyncStorage.setItem('revisionNumber-' + tableId64Cached, response.data.RevisionNumber);
          }
        }
        
        /*
        AsyncStorage.setItem('tableId64', this.tableId64);
       */
  
      })
      .catch(error => console.error(error))
    }
    
  }

  componentWillUnmount = () => {
    if(this.pusher != '' && this.pusher != null && this.channel != '' && this.channel != null){
      this.channel.unbind('order-reload');
      this.pusher.unsubscribe('order-channel');
    }
  }

  retrieveHistory = async () => {
    //console.log("moi nhat");
    var restaurantId = await AsyncStorage.getItem('poolside-restaurantid');
    var orderId = await AsyncStorage.getItem('last-order' + restaurantId);
    //console.log(orderId);
    if(typeof orderId !== 'undefined' && orderId !== null && orderId !== 'null'){
      //alert("connected");
      //try to pull again
      axios({
        method: 'get',
        url: this.apiURL + 'get-order-by-id/' + orderId + "/" + restaurantId
      })
      .then(async (response) => {
        if(response.data.isSuccess === true)
        {
          if(response.data.Order.orderId === orderId)
          {
            AsyncStorage.setItem("intentSecret", response.data.intentSecret);
            AsyncStorage.setItem("acct", response.data.acct);
  
            await this.processData(response.data.Order, restaurantId, orderId);
            
          }
        }
      })
      .catch(error => console.error(error))
    }
    
  }

  initPusher = async () => {
    var _this = this;
    //console.log(config.pusherAppKey);
    this.loadedPusher = true;  
/*
    this.pusher = new Pusher(config.pusherAppKey, {
      cluster: config.pusherCluster,
      encrypted: true
    });*/

    
    this.pusher = new Pusher(config.pusherAppKey, {
      cluster: config.pusherCluster,
      encrypted: true,
      wsHost: config.pusherHost,
      wssPort: config.pusherPort,
    });
    
    this.channel = this.pusher.subscribe('order-channel');

    this.channel.bind('pusher:subscription_succeeded', this.retrieveHistory);

    this.channel.bind('order-reload', async (data) => {
      //console.log(data);
      var restaurantId = await AsyncStorage.getItem('poolside-restaurantid');
      if(typeof restaurantId !== 'undefined' && restaurantId !== null){

        var orderId = await AsyncStorage.getItem('last-order' + restaurantId);
        if(data.orderId === orderId && parseInt(data.restaurantId) === parseInt(restaurantId))
        {
          AsyncStorage.setItem("intentSecret", data.intentSecret);
          AsyncStorage.setItem("acct", data.acct);

          await this.processData(data.model, restaurantId, orderId, true);
          
        }
      }
    });
    /*
    this.channel.bind('order-process', async (data) => {
      //console.log(data);
      var restaurantId = await AsyncStorage.getItem('poolside-restaurantid');
      if(typeof restaurantId !== 'undefined' && restaurantId !== null){

        var orderId = await AsyncStorage.getItem('last-order' + restaurantId);
        if(data.orderId === orderId)
        {
          AsyncStorage.setItem("intentSecret", data.intentSecret);
          AsyncStorage.setItem("acct", data.acct);

          await this.processData(data.model, restaurantId, orderId, true);
          
        }
      }
    });*/
  }

  processData = async (data, restaurantId, orderId, isPush) => {
    var _this = this;
    var menuItems = await AsyncStorage.getItem('menuItems');
    var menuArr = [];
    if(typeof menuItems !== 'undefined' && menuItems !== null){
      menuArr = JSON.parse(menuItems);
      var newCart = [];
      //console.log(data);
      data.OrderDetails.forEach(orderDetail => {
        var item = {};
        var categoryId = 0;
        var itemFiltered = menuArr.filter(function(menuItem){
          return parseInt(menuItem.id) === parseInt(orderDetail.itemId);
        });
        if(itemFiltered.length > 0){
          categoryId = itemFiltered[0].categoryId;
        }
        if(orderDetail.isCombo === true){
          var orderDetailItemList = data.OrderDetailItemList.filter(function(itemList){
            return parseInt(itemList.OrderDetailID) === parseInt(orderDetail.orderDetailId);
          });
          var addOnName = '';
          var addOnIdString = '';
          var addOnKeyListString = '';
          var groupAddOnName = '';
          var addOnPriceListString = '';
          var addOnNameList = [];
          var addOnIds = [];
          var addOnKeyList = [];
          var groupAddOnList = [];
          var addOnPriceList = [];
          orderDetailItemList.forEach(itemList => {
            addOnNameList.push(itemList.AddOnName);
            addOnIds.push(itemList.ItemID);
            addOnKeyList.push(itemList.addOnId);
            groupAddOnList.push(itemList.GroupAddOnName);
            addOnPriceList.push(itemList.Price);
          });

          addOnName = addOnNameList.join(', ');
          addOnIdString = addOnIds.join(',');
          addOnKeyListString = addOnKeyList.join('|||');
          groupAddOnName = groupAddOnList.join('|||');
          addOnPriceListString = addOnPriceList.join('|||');

          item.id = orderDetail.itemId;
          item.itemid = orderDetail.itemId;
          item.price = orderDetail.Price;
          item.itemPrice = orderDetail.itemPrice;
          item.categoryId = categoryId;
          item.quantity = orderDetail.itemQuantity;
          item.name = orderDetail.itemName;
          item.addOnName = addOnName;
          item.addOnIds = addOnIdString;
          item.addOnKeyList = addOnKeyListString;
          item.course = -1;
          item.groupAddOnName = groupAddOnName;
          item.addOnPriceList = addOnPriceListString;
          item.addOnPriceList = addOnPriceListString;
          item.tax = orderDetail.tax;
          item.service = orderDetail.service;
        }else{
          item.id = orderDetail.itemId;
          item.price = orderDetail.Price;
          item.categoryId = categoryId;
          item.quantity = orderDetail.itemQuantity;
          item.name = orderDetail.itemName;
          item.course = -1;
          item.tax = orderDetail.tax;
          item.service = orderDetail.service;
        }
        newCart.push(item);
      });

      AsyncStorage.setItem('poolside-order-items-' + restaurantId, JSON.stringify(newCart));


      //order history
      var listOrderHistory = [];
      var listOrderHistoryString = await AsyncStorage.getItem('order-history' + restaurantId);
      if(listOrderHistoryString !== null && listOrderHistoryString !== ""){
        listOrderHistory = JSON.parse(listOrderHistoryString);
      }

      listOrderHistory = listOrderHistory.filter(function(item){
        return item.id !== orderId && item.isPaid === false;
      });

      var orderHistory = {};
      orderHistory.id = orderId;
      orderHistory.items = newCart;
      orderHistory.isPaid = false;

      listOrderHistory.push(orderHistory);

      AsyncStorage.setItem('order-history' + restaurantId, JSON.stringify(listOrderHistory));
      //console.log('yay');
      if(typeof _this.refreshOrder !== 'undefined'){
        if(typeof isPush === 'undefined'){
          _this.refreshOrder(true, false, data.status);
        }else{
          _this.refreshOrder(true, isPush, data.status, "item-changed");
        }
        
      }
      
      //window.location.reload();
      /*
      console.log(typeof _this.OrderSubmittedScreen);
      
      if(typeof _this.OrderSubmittedScreen != 'undefined' && _this.OrderSubmittedScreen != null){
        await _this.OrderSubmittedScreen.reload();
      }*/
    }
  }

  setOrderSubmittedScreen = element => {
    this.OrderSubmittedScreen = element;
  }

  /*
  refreshOrder = () => {
    console.log("order reload from parent");
  }*/

  

  setDoneLoadFlag = (isAddToHome) => {
    this.isAddToHome = isAddToHome;
  }

  setDoneLoad = () => {
    console.log("Add to home " + this.isAddToHome);
    if(this.isAddToHome){
      this.checkUserAgent();
      this.trackStandalone();
      
      var _this = this;
      //console.log(this.isStandalone);
  
      
  
      if(_this.isSafari && _this.isIOS){
        console.log("Add to home iOS");
        var isShowAddToHomescreen = this.iOSSafariShow();
        if(isShowAddToHomescreen){
          setTimeout(function(){
            _this.ModalIOSHomeScreen.show();
          },2000);
        }
      }else{
        console.log("Add to home android");
        _this.ModalAndroidHomeScreen.show();
        /*
        window.addEventListener('beforeinstallprompt', (e) => {
          console.log("Add to home android detected");
          // show the add button
          _this.promptIntercepted = true;
          // Prevent Chrome 67 and earlier from automatically showing the prompt
          // no matter what, the snack-bar shows in 68 (06/16/2018 11:05 AM)
          e.preventDefault();
          // Stash the event so it can be displayed when the user wants.
          _this.deferredPrompt = e;
    
          setTimeout(function(){
            _this.ModalAndroidHomeScreen.show();
          },2000);
          
        });*/
      }
    }
    
  }

  render(){
    /*
    if (this.state.redirect === '/ask-contact') {
      return <Redirect push to={this.state.redirect} />
    }*/
    //console.log('ok');
    return (
      <Router>
        <Suspense fallback={
          <div className="height-100-percent">
            <div className="spinner">
              <BeatLoader
                size={20}
                color={"#4E858E"}
                loading={true}
              />
              <span className="loadingText">Loading...</span>
            </div>
          </div>
        }>
        

        
        <div className="height-100-percent bg-white">
          <Switch>

            <Route path="/clearcart/:restaurantId" component={clearCart} />

            <Route exact path="/order-history">
              <OrderHistoryScreen />
            </Route>

            <Route exact path="/change-language">
              <ChangeLanguageScreen />
            </Route>


            <Route exact path="/order-submitted">
              <OrderSubmittedScreen setRefresh={click => this.refreshOrder = click} setDoneLoad={this.setDoneLoad}/>
            </Route>
            <Route exact path="/payment">
              <PaymentScreen />
            </Route>
            <Route exact path="/menu">
              <MenuScreen />
            </Route>
            <Route exact path="/item/:itemId">
              <ItemScreen />
            </Route>
            <Route exact path="/askforguests">
              <AskForGuestsScreen />
            </Route>
            <Route exact path="/first">
              <FirstPerson />
            </Route>
            <Route exact path="/second">
              <SecondPerson />
            </Route>
            <Route exact path="/transfer-coordinate">
              <TransferCoordinate />
            </Route>

            <Route exact path="/cart">
              <CartScreen />
            </Route>
            <Route exact path="/order-placed">
              <OrderPlacedScreen />
            </Route>
            <Route exact path="/order-placed/:caseShow">
              <OrderPlacedScreen />
            </Route>
            <Route exact path="/scanmenu/table/:tableId">
              <ScanMenuScreen setDoneLoadFlag={this.setDoneLoadFlag} checkRevision={this.checkRevision}/>
            </Route>
            <Route exact path="/scanmenu/table/:tableId/:isDynamic">
              <ScanMenuScreen setDoneLoadFlag={this.setDoneLoadFlag} checkRevision={this.checkRevision}/>
            </Route>
            <Route exact path="/scanmenu/table/:tableId/:isDynamic/:isInside">
              <ScanMenuScreen setDoneLoadFlag={this.setDoneLoadFlag} checkRevision={this.checkRevision}/>
            </Route>

            <Route exact path="/scanmenu/table-order/:tableId/:isAllOrder">
              <ScanMenuScreen setDoneLoadFlag={this.setDoneLoadFlag} checkRevision={this.checkRevision}/>
            </Route>

            <Route exact path="/scanmenu/allow-order/:tableId/:isAllowOrder">
              <ScanMenuScreen setDoneLoadFlag={this.setDoneLoadFlag} checkRevision={this.checkRevision}/>
            </Route>

            <Route exact path="/allow-order/table/:tableId">
              <AllowOrdersScreen />
            </Route>

            <Route exact path="/input-code">
              <InsertCodeScreen />
            </Route>
            <Route exact path="/menunoshow">
              <MenuNoShowScreen />
            </Route>
            <Route exact path="/showhostcode">
              <ShowHostCodeScreen />
            </Route>
            <Route exact path="/askforsplitthebill">
              <AskForSplitTheBillScreen />
            </Route>
            <Route exact path="/howmanywillcontribute">
              <HowManyWillContributeScreen />
            </Route>
            <Route exact path="/showcodes">
              <ShowCodesScreen />
            </Route>
            <Route exact path="/">
              <HomeScreen />
            </Route>
            <Route exact path="/policy">
              <PolicyScreen />
            </Route>
            <Route exact path="/ask-contact">
              <AskContactScreen />
            </Route>
            <Route exact path="/term">
              <TermScreen />
            </Route>
          </Switch>

          <ModalAddToHomeScreenIOS ref={this.setModalIOSHomeScreen}/>
          <ModalAddToHomeScreenAndroid ref={this.setModalAndroidHomeScreen} addHomeScreen={this.addHomeScreen}/>
        </div>
        </Suspense>
        
      </Router>
      
    );
  }
  
}


export default App;
